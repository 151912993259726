<script setup>
import { Link, router } from '@inertiajs/vue3';
import {ref} from "vue";
import IconSpinner from "@/Components/Icons/IconSpinner.vue";
let props = defineProps({
    level: String,
    href: String,
    fn: String,
    size: {
        type: Number,
        default: 1,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

let dynamicStyles = {
    'font-bold uppercase rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150': true,
    'text-white': props.level !== undefined,

    'text-slate-400 border border-slate-400 hover:bg-slate-200 active:bg-slate-300': props.level === undefined,
    'bg-slate-500 hover:bg-slate-600 active:bg-slate-700': props.level === 'default',
    'bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700': props.level === 'primary',
    'bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-700': props.level === 'success',
    'bg-blue-500 hover:bg-blue-600 active:bg-blue-700': props.level === 'info',
    'bg-amber-500 hover:bg-amber-600 active:bg-amber-700': props.level === 'warning',
    'bg-rose-500 hover:bg-rose-600 active:bg-rose-700': props.level === 'danger',

    'border border-white hover:bg-white/30': props.level === 'alert',

    'text-xs px-2 py-1': 1 >= props.size || props.size > 13,
    'text-sm px-2 py-1': props.size === 2,
    'text-base px-3 py-2': props.size === 3,
    'text-lg px-3 py-2': props.size === 4,
    'text-xl px-3 py-2': props.size === 5,
    'text-2xl px-4 py-3': props.size === 6,
    'text-3xl px-4 py-3': props.size === 7,
    'text-4xl px-5 py-4': props.size === 8,
    'text-5xl px-5 py-4': props.size === 9,
    'text-6xl px-6 py-5': props.size === 10,
    'text-7xl px-6 py-5': props.size === 11,
    'text-8xl px-7 py-6': props.size === 12,
    'text-9xl px-8 py-7': props.size === 13,
}

const inProcess = ref(false)

const loading = () => {
    inProcess.value = true
    router.on('start', () => {
        inProcess.value = true
    })
    router.on('finish', () => {
        setTimeout(() => {
            inProcess.value = false
        }, 1000)
    })
}

</script>

<template>

    <Link v-if="href && !disabled && !inProcess" :href="href" :class="dynamicStyles" @click="loading"><slot/></Link>
    <button v-else-if="disabled || inProcess" type="button" class="opacity-50" :class="dynamicStyles" disabled>
        <span class="flex items-center">
            <IconSpinner v-if="inProcess" aria-hidden="true" class="inline w-4 h-4 mr-1 animate-spin fill-white"/>
            <slot/>
        </span>
    </button>
    <button v-else :class="dynamicStyles"><slot/></button>
</template>
